export type VideoJs = (element: HTMLVideoElement, options?: PlayerOptions, ready?: () => void) => VideoPlayer;

export interface VideoData {
  poster?: string;
  duration?: number;
  subtitleUrl?: string;
  sources: VideoSrc[];
}

export interface PlayerOptions {
  fluid?: boolean;
  aspectRatio?: string;
  techOrder?: string[];
  html5?: any;
  width?: number;
  height?: number;
  defaultVolume?: number;
  children?: string[];
  controls?: boolean;
  src?: VideoSrc[];
  autoplay?: boolean;
  preload?: Preload;
}

export interface VideoSrc {
  src: string;
  type: string;
}

export interface Source {
  type: string;
  src: string;
  overrideNative?: boolean;
}

export interface VideoPlayer {
  play(): VideoPlayer;

  pause(): VideoPlayer;

  paused(): boolean;

  src(newSource: string | Source | Source[]): VideoPlayer;

  currentTime(seconds: number): VideoPlayer;

  currentTime(): number;

  duration(): number;

  buffered(): TimeRanges;

  bufferedPercent(): number;

  volume(percentAsDecimal: number): TimeRanges;

  volume(): number;

  muted(): boolean;

  width(): number;

  width(pixels: number): VideoPlayer;

  height(): number;

  height(pixels: number): VideoPlayer;

  size(width: number, height: number): VideoPlayer;

  requestFullScreen(): VideoPlayer;

  cancelFullScreen(): VideoPlayer;

  ready(callback: (this: VideoPlayer) => void): VideoPlayer;

  on(eventName: string | string[] | PlayerEvents[], callback: (eventObject: Event) => void): void;

  off(eventName?: string, callback?: (eventObject: Event) => void): void;

  dispose(): void;

  addRemoteTextTrack(options: {}, a?: boolean): HTMLTrackElement;

  removeRemoteTextTrack(track: HTMLTrackElement): void;

  remoteTextTracks(): any[];

  poster(val?: string): string | VideoPlayer;

  playbackRate(rate?: number): number;
}

export enum Preload {
  metadata,
  auto,
  none
}

export interface TextTrack {
  kind: string;
  language: string;
  label: string;
  src: string;
  mode: 'showing' | 'disabled';
}

export enum PlayerEvents {
  loadstart = 'loadstart',
  progress = 'progress',
  suspend = 'suspend',
  abort = 'abort',
  error = 'error',
  emptied = 'emptied',
  stalled = 'stalled',
  loadedmetadata = 'loadedmetadata',
  loadeddata = 'loadeddata',
  canplay = 'canplay',
  canplaythrough = 'canplaythrough',
  playing = 'playing',
  waiting = 'waiting',
  seeked = 'seeked',
  ended = 'ended',
  durationchange = 'durationchange',
  timeupdate = 'timeupdate',
  seeking = 'seeking',
  play = 'play',
  pause = 'pause',
  ratechange = 'ratechange',
  resize = 'ratechange',
  volumechange = 'volumechange',
}
